import { IMenuDT } from "@/types/menu-d-t";

const menu_data: IMenuDT[] = [
  {
    id: 1,
    title: "Home",
    link: "/",
  },
  {
    id: 2,
    title: "Services",
    link: "/service",
  },
  {
    id: 3,
    title: "About Us",
    link: "/about-us",
  },
  {
    id: 4,
    title: "Blog",
    link: "/blog-classic",
  },
  {
    id: 5,
    title: "Gallery",
    link: "/portfolio-grid-col-3",
  },
  {
    id: 6,
    title: "Contact",
    link: "/contact/contact-2",
  },
  {
    id: 7,
    title: "Faq",
    link: "/faq",
  },
];

export default menu_data;

// mobile menus
export const mobile_menu_data: {
  id: number;
  title: string;
  link: string;
  dropdown_menus?: {
    title: string;
    link: string;
  }[];
}[] = [
  {
    id: 1,
    title: "Home",
    link: "/",
  },
  {
    id: 2,
    title: "Services",
    link: "/service",
  },
  {
    id: 3,
    title: "About Us",
    link: "/about-us",
  },
  {
    id: 4,
    title: "Blog",
    link: "/blog-classic",
  },
  {
    id: 5,
    title: "Gallery",
    link: "/portfolio-grid-col-3",
  },
  {
    id: 6,
    title: "Contact",
    link: "/contact/contact-2",
  },
  {
    id: 7,
    title: "Faq",
    link: "/faq",
  },
];
